<template>
  <div 
    class="container"
  >
    <div class="copyright">{{copyright}}</div>
  </div>
</template>

<script>
// import Categories from './Categories'
// import Link from './Link'
export default {
  props: {
    copyright: String
  },
  components: {
    // Categories,
    // Link
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/buttons";
@import "../styles/grid";
@import "../styles/text";

  .container {
    @extend %container;
    @extend %mobile-container;
    display: flex;
    /* margin-top: calc(60px - 10px); */
    /* margin-bottom: 175px; */

    @media (max-width: 800px) {
      /* margin-bottom: 110px; */

    }

    .copyright {
      width: 100%;
      /* margin-top: 50px; */
      @include b3;
      text-align: center;
      opacity: 0.6;

      @media (max-width: 800px) {
        @include m-b3;
      }
    }

  }

</style>
