<template>
  <div class="form-container">
    <transition name="form-fade">
      <p
        class="thank-you"
        v-if="formSent === true"
        v-html="'Thank you! You’ll hear from us soon.'"
      />
      <form
        v-else
        action="#"
        method="post"
        @submit.prevent="submitForm"
        :class="{loading: submitIsDisabled}"
      > 
        <p class="instructions">Subscribe to our mailing list to follow our work</p>
        <div class="underline-group">
          <input
            type="email"
            v-model="email"
            placeholder="What’s your email?"
            autocomplete="email"
            required
          />
          <button type="submit">
            <span>Subscribe</span>
          </button>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      email: '',
      submitIsDisabled: false,
      formSent: false
    }
  },
  methods: {
    // Submit Form.
    async submitForm () {
      try {
        // Disable Submission.
        this.submitIsDisabled = true
        // Data.
        const { email } = this
        if (!email) throw new Error('!Email')

        // Axios User.
        const baseURL = 'https://lightandshade.now.sh' /* Prod */ || 'http://localhost:3000' /* Dev. */
        const subscribeUserEndpoint = `${baseURL}/subscribe?email=${email}`
        // console.log(subscribeUserEndpoint)
        await axios.post(
          subscribeUserEndpoint, 
          { email }, 
          { headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        // Flag Form As Sent.
        this.formSent = true
      } catch (error) {
        // Disable Submission.
        this.submitIsDisabled = false
      }
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/buttons";
@import "../styles/text";
@import "../styles/theme";
@import "../styles/grid";
@import 'px-par/index.scss';

.form-fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
}
.form-fade-leave-active {
  transition: opacity 0.5s;
  opacity: 1;
}
.form-fade-enter, .form-fade-leave-to {
  transition: opacity 0.5s;
  opacity: 0;
}
.form-container {
  position: relative;
  height: px(101);
  width: 100%;
}

.thank-you {
  position: absolute;
  width: 100%;
  height: px(101);
  top: 0px;
  left: 0px;

  @include b2;
  text-align: center;
  @media (max-width: 800px) {
    @include m-b2;

  }
}

form {
  width: 100%;

  height: px(101);
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 800px) {
    flex-direction: column;
    width: calc(100% - #{m-grid(1)});
    margin-left: m-grid(0.5);
    margin-right: m-grid(0.5);
  }

  &.loading, &.loading button, &.loading input[type=email] {
    cursor: progress;
  }
}

.underline-group {
  width: px_par(1320,379);
  max-width: 318px;
  height: px(27+24);
  display: flex;
  border-bottom: 1px solid $red;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 100%;
  }

  input[type=email] {
    padding: 12px 0px;
    width: 100%;
    height: px(27+24);
    border: none;
    border-radius: 0;
    @include b2;
    box-sizing: border-box;
    background-color: transparent;
    border-bottom-color: transparent !important;
    border-bottom: 0px solid transparent !important;
    color: white;
    opacity: 0.6;

    @media (max-width: 800px) {
      @include m-b2;
      font-size: px(16);
      line-height: px(20);
    }
    &::placeholder {
      color: white;
      opacity: 0.5;
    }
  }

  button {
    @extend %btn-focus-clear;
    height: px(27+24);
    padding: 0px;
    background: transparent;
    border: 0px;
    color: $red;
    cursor: pointer;
    
    span {
      opacity: 0.5;
    }
  }
} 


.instructions {
  width: px_par(1320,379);
  max-width: 379px;
  /* height: px(26); */
  margin-top: px(12);
  margin-bottom: px(12);
  margin-right: 15px;

  @include b2;
  @media (max-width: 800px) {
    width: 100%;
    max-width: 100%;
    margin: 0px;
    @include m-b2;
  }
}

// when form valid fade the button in
form:valid {
  button span {
    transition: opacity 0.3s;
    opacity: 1;
  }
}

input[type=email]:focus {
  @extend %btn-focus-clear;
  background-color: transparent;
  color: $red;
}

input[type=email]:valid {
  @extend %btn-focus-clear;
  color: $red;
}

</style>
